import React from 'react';
import './About.css';

const AboutUs = () => {
    const handleGetQuoteClick = () => {
        window.location.href = '/'; // Adjust this path if needed
    };

    return (
        <div className="about-us-container">
            <h1>Welcome to The Mauritius Dream</h1>
            <p>
                Welcome to The Mauritius Dream, where your journey to paradise begins. We are dedicated to crafting
                unforgettable travel experiences that showcase the beauty, culture, and magic of Mauritius—an island
                that truly lives up to its dreamy reputation.
            </p>
            <h2>Our Story</h2>
            <p>
                The Mauritius Dream was born from a deep passion for travel and a love for this incredible island. Our
                founder, originally from South Africa, made the bold decision to leave behind the familiar and embrace
                the vibrant life of Mauritius. Enchanted by its crystal-clear waters, lush landscapes, and warm,
                welcoming people, they set out to share this hidden gem with travelers from around the world.
            </p>
            <h2>Why Choose Us?</h2>
            <p>
                At The Mauritius Dream, we believe that travel is more than just a vacation; it’s an opportunity to
                connect with the world in a meaningful way. Living on the island gives us an intimate understanding of
                its rhythms, secrets, and best-kept treasures. We use this insider knowledge to create tailor-made
                experiences that go beyond the ordinary, allowing you to discover the true heart of Mauritius. Whether
                you're seeking relaxation on pristine beaches, adventure in the wild, or a deep dive into the local
                culture, we're here to make your dream holiday a reality. Let us guide you through the wonders of
                Mauritius, ensuring every moment is as magical as the island itself.
            </p>
            <h2>Join Us on This Journey</h2>
            <p>
                We invite you to explore Mauritius through our eyes, to fall in love with its beauty, and to create
                memories that will last a lifetime. At The Mauritius Dream, your perfect island escape is just a click
                away. Thank you for choosing us as your travel partner. We can't wait to share the magic of Mauritius
                with you.
            </p>
            <button
                className="get-quote-button"
                onClick={handleGetQuoteClick}
            >
                Get a Quote for Your Dream
            </button>
        </div>
    );
};

export default AboutUs;
