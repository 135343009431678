// import React, { useState } from 'react';
// import './Contact.css';

// const Contact = () => {
//     const [formData, setFormData] = useState({
//         fullName: '',
//         email: '',
//         phone: '',
//         message: ''
//     });

//     const [formSubmitted, setFormSubmitted] = useState(false);
//     const [error, setError] = useState(null);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleFormSubmit = async (e) => {
//         e.preventDefault();

//         // Validation
//         if (!formData.fullName || !formData.email || !formData.phone || !formData.message) {
//             setError('All fields are required.');
//             return;
//         }

//         setError(null);

//         try {
//             const response = await fetch('/api/sendEmail', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify(formData),
//             });

//             if (response.ok) {
//                 setFormSubmitted(true);
//                 setFormData({
//                     fullName: '',
//                     email: '',
//                     phone: '',
//                     message: ''
//                 });
//             } else {
//                 throw new Error('Failed to send message.');
//             }
//         } catch (err) {
//             setError('An error occurred while sending your message. Please try again later.');
//         }
//     };

//     return (
//         <div className="contact-container">
//             <h1>Contact Us</h1>
//             {formSubmitted ? (
//                 <p>Thank you for your message! We will get back to you shortly.</p>
//             ) : (
//                 <form onSubmit={handleFormSubmit}>
//                     <div className="form-group">
//                         <label>Full Name</label>
//                         <input
//                             type="text"
//                             name="fullName"
//                             value={formData.fullName}
//                             onChange={handleInputChange}
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label>Email Address</label>
//                         <input
//                             type="email"
//                             name="email"
//                             value={formData.email}
//                             onChange={handleInputChange}
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label>Telephone Number</label>
//                         <input
//                             type="text"
//                             name="phone"
//                             value={formData.phone}
//                             onChange={handleInputChange}
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label>Message</label>
//                         <textarea
//                             name="message"
//                             value={formData.message}
//                             onChange={handleInputChange}
//                             required
//                         ></textarea>
//                     </div>
//                     {error && <p className="error">{error}</p>}
//                     <button type="submit">Send Message</button>
//                 </form>
//             )}
//         </div>
//     );
// };

// export default Contact;
import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        message: ''
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validation
        if (!formData.fullName || !formData.email || !formData.phone || !formData.message) {
            setError('All fields are required.');
            return;
        }

        setError(null);

        try {
            const response = await fetch('/api/sendEmail', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setFormSubmitted(true);
                setFormData({
                    fullName: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            } else {
                throw new Error('Failed to send message.');
            }
        } catch (err) {
            setError('An error occurred while sending your message. Please try again later.');
        }
    };

    const handleBackToDream = () => {
        window.location.href = '/'; // Adjust this path if necessary
    };

    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            {formSubmitted ? (
                <div className="success-message">
                <p>Thank you for your message! We will get back to you shortly.</p>
                <p>Please email <a href="mailto:info@themauritiusdream.com">info@themauritiusdream.com</a> if you require anything else.</p>
                <button onClick={handleBackToDream} className="back-to-dream-button">
                    Back to the Dream
                </button>
            </div>
            ) : (
                <form onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label>Full Name</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Email Address</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Telephone Number</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Message</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                        ></textarea>
                    </div>
                    {error && <p className="error">{error}</p>}
                    <button type="submit">Send Message</button>
                </form>
            )}
        </div>
    );
};

export default Contact;
