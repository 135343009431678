import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Gallery.css';

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    // List of images in the gallery
    const images = [
        '/img/gallery/image1.jpg',
        '/img/gallery/image2.jpg',
        '/img/gallery/image3.jpg',
        '/img/gallery/image4.jpg',
        '/img/gallery/image5.jpg',
        '/img/gallery/image6.jpg',
        '/img/gallery/image7.jpg',
        '/img/gallery/image8.jpg',
        '/img/gallery/image9.jpg',
        '/img/gallery/image10.jpg',
    ];

    // Carousel settings for react-multi-carousel
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const handleBackToDream = () => {
        window.location.href = '/'; // Adjust this path if necessary
    };

    return (
        <div className="gallery-container">
            <h1>Gallery</h1>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                showDots={true}
                customTransition="transform 0.5s"
                itemClass="carousel-item"
            >
                {images.map((image, index) => (
                    <div key={index} className="image-slide">
                        <img
                            src={image}
                            alt={`Gallery item ${index + 1}`}
                            onClick={() => handleImageClick(image)}
                        />
                    </div>
                ))}
            </Carousel>

            {/* "Back to the Dream" button below the carousel */}
            <button onClick={handleBackToDream} className="back-to-dream-button">
                Back to the Dream
            </button>

            {/* Modal for full-size image */}
            {selectedImage && (
                <div className="image-modal">
                    <div className="modal-overlay" onClick={closeModal}></div>
                    <div className="modal-content">
                        <img src={selectedImage} alt="Full View" />
                        <button className="close-button" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Gallery;
