import React from 'react';

// Import the Google Font for Alex Brush
const alexBrushFont = document.createElement("link");
alexBrushFont.href = "https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap";
alexBrushFont.rel = "stylesheet";
document.head.appendChild(alexBrushFont);

const Header = () => (
    <header style={headerStyle}>
        <h1 style={titleStyle}>The Mauritius Dream</h1>
    </header>
);

// Header styling applied directly in JavaScript
const headerStyle = {
    position: 'fixed',
    width: '100%',
    top: '0',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Transparent grey
    height: '60px', // Explicit height to ensure height is reduced
    display: 'flex',
    justifyContent: 'center', // Center everything
    alignItems: 'center',
    zIndex: '1',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

// Title styling for the header
const titleStyle = {
    fontFamily: "'Alex Brush', cursive",
    fontSize: '2rem', // Adjusted the font size to fit better within the reduced height
    color: '#61acb4', // Color to match the quote button
    textAlign: 'center',
    margin: '0', // Removing default margin for better vertical alignment
    
};

export default Header;
