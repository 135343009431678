import React, { useState } from 'react';
import './BookingForm.css';

const BookingForm = () => {
    const [formData, setFormData] = useState({
        hotelCode: 'MAU',
        dateDeparture: '',
        dateReturn: '',
        departureCity: 'JOHANNESBURG',
        includeAir: true,
        flightAdvanced: 'All Airlines',
        flightCabin: 'Cheapest',
        honeymoonRates: false,
        repeaterRates: false,
        weddingRates: false,
        nbrAdults: '2',
        nbrChildren: '0',
        nbrInfants: '0'
    });
    const [apiResponse, setApiResponse] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [isGalleryOpen, setGalleryOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state for API request

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when Get Quote is clicked
        setApiResponse(null); // Reset API response before new request

        const payload = {
            ...formData,
            dateDeparture: new Date(formData.dateDeparture).toISOString(),
            dateReturn: new Date(formData.dateReturn).toISOString(),
        };
        
        console.log('Sending request to Beachcomber API with payload:', payload);
        try {
            const response = await fetch('/api/getQuote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BeachcomberKey': 'YOUR_API_KEY_HERE'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch room options');
            }

            const data = await response.json();
            setApiResponse(data);
        } catch (error) {
            console.error('Error during API request:', error);
        } finally {
            setLoading(false); // Set loading to false when request is complete
        }
    };

    const handleImageClick = (images) => {
        setSelectedImages(images);
        setGalleryOpen(true);
    };

    const closeGallery = () => {
        setGalleryOpen(false);
    };

    const handleGetAnotherQuote = () => {
        setApiResponse(null);
        setFormData({
            hotelCode: 'MAU',
            dateDeparture: '',
            dateReturn: '',
            departureCity: 'JOHANNESBURG',
            includeAir: true,
            flightAdvanced: 'All Airlines',
            flightCabin: 'Cheapest',
            honeymoonRates: false,
            repeaterRates: false,
            weddingRates: false,
            nbrAdults: '2',
            nbrChildren: '0',
            nbrInfants: '0'
        });
    };

    const formatCurrency = (amount) => {
        return `R${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
    };

    return (
        <div className="booking-container">
            {/* Section with three boxes linking to pages */}
            <div className="info-boxes">
                <div className="info-box" onClick={() => window.location.href = '/about'}>
                    <img src="/box1.png" alt="Box 1" className="box-image" />
                    <h3>About Us</h3>
                </div>
                <div className="info-box" onClick={() => window.location.href = '/gallery'}>
                    <img src="/box2.png" alt="Box 2" className="box-image" />
                    <h3>Gallery</h3>
                </div>
                <div className="info-box" onClick={() => window.location.href = '/contact'}>
                    <img src="/box3.png" alt="Box 3" className="box-image" />
                    <h3>Contact Us</h3>
                </div>
            </div>

            {apiResponse ? (
                apiResponse.errorMsg ? (
                    <div className="no-availability">
                        <h3>Unfortunately, there is no availability at this time for your selected dates at the hotel.</h3>
                        <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
                            Get Another Quote
                        </button>
                    </div>
                ) : (
                    <>
                        <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
                            Get Another Quote
                        </button>

                        {/* Display hotel overview and gallery */}
                        <div className="hotel-overview">
                            <h3>{apiResponse.hotelName}</h3>
                            <p>{apiResponse.hotelDescription}</p>
                            <div className="hotel-gallery">
                                {apiResponse.hotelImages?.map((image, imgIndex) => (
                                    <img key={imgIndex} className="thumbnail" src={image.imageURL} alt={`Hotel image ${imgIndex + 1}`} />
                                ))}
                            </div>
                        </div>

                        {/* Display room options */}
                        {apiResponse.roomOptions && apiResponse.roomOptions.length > 0 && (
                            apiResponse.roomOptions.map((room, index) => (
                                <div key={index} className="room-box">
                                    <div className="room-description">
                                        <h5>{room.accomProductName}</h5>
                                        <p>{room.accomProdDescription}</p>
                                        <h6>Packages Available:</h6>
                                        {room.packages?.map((pkg, pkgIndex) => (
                                            <div key={pkgIndex} className="package-box">
                                                <p><strong>Package:</strong> {pkg.packageDesc}</p>
                                                <p><strong>Total Price (ZAR):</strong> {formatCurrency(pkg.packagePriceZARFrom)}</p>
                                                <p><strong>Price per Person (ZAR):</strong> {formatCurrency(pkg.pricePerPersonZARFrom)}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <img className="room-image" src={room.productImages[0].imageURL} alt={`Room image ${index + 1}`} />
                                    </div>
                                </div>
                            ))
                        )}

                        {/* Display flight information */}
                        {apiResponse.airGDS && apiResponse.airGDS.length > 0 && (
                            apiResponse.airGDS.map((flight, flightIndex) => (
                                <div key={flightIndex} className="flight-box">
                                    <p><strong>From:</strong> {flight.fromArpt} ({flight.fromArptCode})</p>
                                    <p><strong>To:</strong> {flight.toArpt} ({flight.toArptCode})</p>
                                    <p><strong>Carrier:</strong> {flight.carrier}</p>
                                    <p><strong>Departure:</strong> {new Date(flight.depDateTime).toLocaleString()}</p>
                                    <p><strong>Arrival:</strong> {new Date(flight.arrDateTime).toLocaleString()}</p>
                                </div>
                            ))
                        )}

                        {/* Display inclusions */}
                        {apiResponse.inclusions && apiResponse.inclusions.length > 0 && (
                            <div className="inclusions-section">
                                <h6>Inclusions:</h6>
                                <ul>
                                    {apiResponse.inclusions.map((inclusion, idx) => (
                                        <li key={idx}>{inclusion}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </>
                )
            ) : (
                <form onSubmit={handleFormSubmit} className="booking-form">
                    <div className="form-row">
                        <div className="form-group full-width">
                            <label>Choose your Hotel</label>
                            <select name="hotelCode" value={formData.hotelCode} onChange={handleInputChange}>
                                <option value="MAU">Mauricia Beachcomber</option>
                                <option value="CAN">Cannonier Beachcomber</option>
                                <option value="VIC">Victoria Beachcomber</option>
                                <option value="PAR">Paradis Beachcomber</option>
                                <option value="SHA">Shandrani Beachcomber</option>
                                <option value="DIN">Dinarobin Beachcomber</option>
                                <option value="TAB">Troux Au Biches Beachcomber</option>
                                <option value="RP">Royal Palm Beachcomber</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group left-align">
                            <label>Select Departure Date</label>
                            <input type="date" name="dateDeparture" value={formData.dateDeparture} onChange={handleInputChange} required />
                        </div>
                        <div className="form-group right-align">
                            <label>Select Return Date</label>
                            <input type="date" name="dateReturn" value={formData.dateReturn} onChange={handleInputChange} required />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group left-align">
                            <label>Departure City</label>
                            <select name="departureCity" value={formData.departureCity} onChange={handleInputChange}>
                                <option value="JOHANNESBURG">Johannesburg</option>
                                <option value="CAPE TOWN">Cape Town</option>
                                <option value="DURBAN">Durban</option>
                            </select>
                        </div>
                        <div className="form-group right-align">
                            <label>Select Flight Class</label>
                            <select name="flightCabin" value={formData.flightCabin} onChange={handleInputChange}>
                                <option value="Cheapest">Cheapest</option>
                                <option value="Business">Business</option>
                                <option value="Economy">Economy</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group left-align">
                            <label>Number of Adults</label>
                            <input type="number" name="nbrAdults" value={formData.nbrAdults} onChange={handleInputChange} min="1" max="6" />
                        </div>
                        <div className="form-group center-align">
                            <label>Number of Children</label>
                            <input type="number" name="nbrChildren" value={formData.nbrChildren} onChange={handleInputChange} min="0" max="4" />
                        </div>
                        <div className="form-group right-align">
                            <label>Number of Infants</label>
                            <input type="number" name="nbrInfants" value={formData.nbrInfants} onChange={handleInputChange} min="0" max="2" />
                        </div>
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Get Quote'}
                    </button>
                </form>
            )}

            {loading && <p>Loading room options...</p>}

            {isGalleryOpen && (
                <div className="gallery-popup">
                    <div className="gallery-overlay" onClick={closeGallery}></div>
                    <div className="gallery-content">
                        {selectedImages.map((image, imgIndex) => (
                            <img key={imgIndex} src={image.imageURL} alt={`Gallery image ${imgIndex + 1}`} />
                        ))}
                        <button className="close-button" onClick={closeGallery}>Close</button>
                    </div>
                </div>
            )}

            <footer className="footer">
                {/* © 2024 Mauritius Dream. All rights reserved. */}
            </footer>
        </div>
    );
};

export default BookingForm;
